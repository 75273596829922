<template>
  <div class="mainwidth">
    <div class="details">
      <h2>{{ newsDetail.title }}</h2>
      <div style="display: flex;justify-content: space-around;font-size: 16px;">
        <p>日期:{{ newsDetail.start_time }}</p>
        <p>来源:{{ newsDetail.source }}</p>
      </div>
      <div class="news-content" v-html="content"></div>
      <div style="text-align: center;width: 100%;">
        <div class="download" @click="handleClickDownload(newsDetail)">原文下载</div>
      </div>
      <div class="declare" @click="handleClickDeclare" v-show="isflag">
        政策申报
      </div>
    </div>
    <!-- 政策申报 -->
    <a-modal
      v-model:visible="declareShow"
      :centered="true"
      title="政策申报"
      width="30%"
      :maskClosable="false"
      @ok="handleOk"
      @cancel="handleClickCancle"
    >
      <a-form :label-col="labelCol" :wrapper-col="wrapperCol" layout="vertical">
        <a-form-item label="申报企业名称:">
          <a-input
            v-model:value="enterpriseName"
            placeholder="请输入申报企业名称"
          />
        </a-form-item>
        <a-form-item label="企业联系人:">
          <a-input
            v-model:value="contactPerson"
            placeholder="请输入企业联系人"
          />
        </a-form-item>
        <a-form-item label="联系方式:">
          <a-input v-model:value="contactPhone" placeholder="请输入联系方式" />
        </a-form-item>
      </a-form>
    </a-modal>
    <!-- 申报成功弹窗 -->
    <a-modal
      v-model:visible="policySuccess"
      :centered="true"
      title="申报结果"
      width="35%"
      :maskClosable="false"
      :footer="null"
    >
      <div class="success-title">
        感谢您的支持,您的资料已填写成功,请耐心等待工作人员和您联系。
      </div>
      <div class="success-text">
        <p style="font-weight: bold;">如需进一步了解请拨打业务专线进行咨询:</p>
        <p>
          <span style="font-size: 16px;font-weight: bold;">数字化转型服务:</span
          ><span style="margin-left: 10px">卢思冰</span
          ><span style="color: #1089ff;margin-left: 5px;">15765999441</span>
        </p>
        <p>
          <span style="font-size: 16px;font-weight: bold;">融资服务:</span>
          <span style="margin-left: 10px">李志伟</span
          ><span style="color: #1089ff;margin-left: 5px;">18245108077</span>
        </p>
        <p>
          <span style="font-size: 16px;font-weight: bold;">政策申请: </span
          ><span style="margin-left: 10px">侯惠宁</span
          ><span style="color: #1089ff;margin-left: 5px;">18745349632</span>
        </p>
      </div>
      <template #footer>
        <!-- 自定义footer，只包含确定按钮 -->
        <a-button type="primary" @click="handleOk">确定</a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import { getPolicyDetails, declarationAdd } from "../api/financialPrefecture";
import { Base64 } from "js-base64";
import { mapGetters } from "vuex";
export default {
  name: "PolicyDetails",
  data() {
    return {
      newsDetail: {},
      declareShow: false,
      policySuccess: false,
      labelCol: {
        span: 12,
      },
      wrapperCol: {
        span: 18,
      },
      id: "",
      enterpriseName: "",
      contactPerson: "",
      contactPhone: "",
      content: "",
      status: "",
      isflag: true,
    };
  },
  computed: {
    ...mapGetters([
      "orgId",
      "isAuth",
      "loginType",
      "orgName",
      "isLogin",
      "userId",
    ]),
  },
  methods: {
    getInfo() {
      getPolicyDetails({ id: this.id }).then((res) => {
        console.log(res.pd);
        this.newsDetail = res.pd;
        this.content = Base64.decode(res.pd.content);
        // this.status = res.pd.
      });
    },
    // 原文下载
    handleClickDownload(item){
      if (item.url) {
        const urls = item.url.split(","); // 将URL字符串分割成数组
        urls.forEach((url) => {
          window.open(url.trim(), "_blank"); // 使用trim()去除可能的空白字符
        });
      } else {
        this.$message.info("无附件");
      }
    },
    // 政策申报
    handleClickDeclare() {
      if (this.isLogin) {
        this.declareShow = true;
      } else {
        this.$message.error("请先登录");
        this.$router.push("/");
      }
    },
    // 验证手机号的正则表达式（以中国大陆手机号为例）
    validatePhoneNumber(phone) {
      const regex = /^1[3-9]\d{9}$/;
      return regex.test(phone);
    },
    handleOk() {
      if (this.enterpriseName == "undefined" || this.enterpriseName == "") {
        this.$message.info("请输入申报企业名称");
        return false;
      } else if (
        this.contactPerson == "undefined" ||
        this.contactPerson == ""
      ) {
        this.$message.info("请输入企业联系人");
        return false;
      } else if (this.contactPhone == "undefined" || this.contactPhone == "") {
        this.$message.info("请输入联系方式");
        return false;
      } else if (!this.validatePhoneNumber(this.contactPhone)) {
        // 如果手机号不合法，设置错误信息
        this.$message.info("请输入有效的手机号");
        return false;
      }
      if (this.status == "0") {
        this.$message.info("已经申报该政策");
      } else if (this.status == "1") {
        let params = {
          policy_info_id: this.id,
          enterprise_name: this.enterpriseName,
          contact_person: this.contactPerson,
          contact_phone: this.contactPhone,
          type: "3",
          user_id: this.userId,
        };
        declarationAdd(params).then((res) => {
          console.log(res);
          if (res.result == "success") {
            this.declareShow = false;
            this.policySuccess = true;
            this.enterpriseName = "";
            this.contactPerson = "";
            this.contactPhone = "";
            this.isflag = false;
          }
        });
      }
    },
    handleClickCancle() {
      this.enterpriseName = "";
      this.contactPerson = "";
      this.contactPhone = "";
    },
  },
  mounted() {
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
      this.status = this.$route.query.status;
      // 政策详情
      this.getInfo();
    }
  },
};
</script>

<style lang="less" scoped>
.mainwidth {
  padding-top: 20px;
  padding-bottom: 20px;

  .details {
    position: relative;
    min-height: 400px;
    h2 {
      text-align: center;
      font-size: 24px;
      font-weight: bold;
    }
  }
}
.news-content {
  margin: 20px auto;
  font-size: @font-lg;
  color: @color-base;
  line-height: 30px;
  text-align: justify;
}
.declare {
  position: fixed;
  right: 160px;
  top: 500px;
  background: #fff;
  padding: 6px 10px;
  border: 1px solid #ccc;
  box-shadow: 0px 0px 10px #929292;
  border-radius: 5px;
  cursor: pointer;
}
.download {
  background: #457dfe;
  padding: 6px 10px;
  border: 1px solid #ccc;
  box-shadow: 0px 0px 10px #929292;
  border-radius: 5px;
  cursor: pointer;
  color: #fff;
  width: 200px;
  text-align: center;
  margin-left: 50%;
  transform: translateX(-50%);
}
.success-title {
  font-size: 18px;
  text-align: center;
  font-weight: bold;
}
.success-text {
  margin-top: 20px;
  font-size: 16px;
  text-align: center;
}
::v-deep .ql-align-right {
  text-align: right;
}
::v-deep .ql-align-center {
  text-align: center;
}
</style>
